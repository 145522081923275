<template>
  <div>
    <v-alert
      v-if="positivealert"
      dense
      text
      type="success"
    >
      {{ $t('profileupdatesuccess') }}
    </v-alert>
    <v-alert
      v-if="negativealert"
      dense
      text
      type="error"
    >
      {{ $t('profileupdatefailed') }}
    </v-alert>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="9"
      >
        <v-card
          height="100%"
          class="mx-3"
        >
          <v-card-subtitle class="font-weight-black">
            <h3>{{ $t(('editprofile')) }}</h3>
          </v-card-subtitle>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.firstName"
                  outlined
                  :label="$t('firstname')"
                  dense
                  prepend-inner-icon="mdi-rename-box"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.lastName"
                  outlined
                  :label="$t('lastname')"
                  dense
                  prepend-inner-icon="mdi-rename-box"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.phone"
                  outlined
                  :label="$t('phone-number')"
                  dense
                  prepend-inner-icon="mdi-phone"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="date"
                  outlined
                  type="date"
                  :label="$t('birthdate')"
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('address-Line')"
                  rules="required"
                >
                  <v-text-field
                    v-model="userData.addressData.addressLine"
                    :label="$t('address-Line')"
                    :error-messages="errors"
                    outlined
                    dense
                    prepend-inner-icon="mdi-map-marker"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('city')"
                  rules="required"
                >
                  <v-text-field
                    v-model="userData.addressData.city"
                    :label="$t('city')"
                    :error-messages="errors"
                    outlined
                    dense
                    prepend-inner-icon="mdi-city"
                  />
                </validation-provider>

                <v-row dense>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('zip-code')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="userData.addressData.zipCode"
                        :label="$t('zip-code')"
                        type="number"
                        :error-messages="errors"
                        outlined
                        dense
                        prepend-inner-icon="mdi-code-equal"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('state')"
                      rules="required"
                    >
                      <v-text-field
                        v-model="userData.addressData.state"
                        :label="$t('state')"
                        :error-messages="errors"
                        outlined
                        dense
                        prepend-inner-icon="mdi-map-search"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-select
                  v-model="userData.addressData.country"
                  :items="countriesList"
                  :label="$t('country')"
                  outlined
                  dense
                  prepend-inner-icon="mdi-flag"
                />
                <v-radio-group
                  v-model="userData.addressData.addressType"
                  class="mx-6"
                  row
                >
                  <v-radio
                    label="Home Address"
                    :value="1"
                  />
                  <v-radio
                    label="Business Address"
                    :value="2"
                  />
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <template>
                  <l-map
                    ref="map"
                    :zoom="addressData.zoom"
                    :center="{lat: addressData.markers.lat, lng: addressData.markers.long}"
                    style="z-index: 0"
                    @ready="onReady"
                  >
                    <l-control-layers position="topright" />
                    <l-tile-layer
                      v-for="tileProvider in tileProviders"
                      :key="tileProvider.name"
                      :name="tileProvider.name"
                      :visible="tileProvider.visible"
                      :url="tileProvider.url"
                      :attribution="tileProvider.attribution"
                      layer-type="base"
                    />
                    <l-control-scale
                      position="bottomleft"
                      :imperial="false"
                      :metric="true"
                    />
                    <l-control position="bottomleft">
                      {{ addressData.markers.lat + ' ' + addressData.markers.long }}
                    </l-control>
                    <l-marker
                      ref="marker"
                      :lat-lng="{lat: addressData.markers.lat, lng: addressData.markers.long}"
                      :draggable="true"
                      @dragend="onDragEnd"
                    />
                  </l-map>
                </template>
              </v-col>
            </v-row>
          </v-container>
          <div
            class="ma-5 borderRadius"
          >
            <v-row>
              <v-col
                cols="8"
                sm="8"
                md="11"
              >
                <div class="d-flex align-center pa-2 mr-4">
                  <v-icon
                    class="ma-3"
                    size="25"
                  >
                    mdi-key-variant
                  </v-icon>
                  <div>
                    <h3>{{ $t('changepassword') }}</h3>
                    <span class="grey--text"> {{ $t('passwordtext') }}</span>
                  </div>
                </div>
              </v-col>
              <v-col
                class="d-flex align-center justify-end"
                cols="4"
                sm="4"
                md="1"
                lg="1"
                xl="1"
              >
                <v-btn
                  small
                  @click="editpassword"
                >
                  {{ $t('edit') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              tile
              color="primary"
              class="mx-5 align-self-end rounded"
              @click="save"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              {{ $t('updateprofile') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="4"
        xl="3"
      >
        <v-card
          class="mx-3"
          height="100%"
        >
          <v-img
            lazy-src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJEK40TRPKbM5JcPw1M6F8ayHInpCGrTNSrg&usqp=CAU"
            height="200px"
            src="https://images.unsplash.com/photo-1545287072-e39ac363b3c8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80"
          />
          <div class="mb-10">
            <v-card-title class="white--text">
              <div class="positionBadge">
                <img
                  alt="user"
                  :src="`${returnimage}`"
                  class="imageclass"
                  height="150px"
                  width="150px"
                >
                <v-icon
                  color="primary"
                  @click="uploadDialog = true"
                >
                  mdi-pencil
                </v-icon>
              </div>

              <!-- <v-btn @click="uploadDialog=true">
                klik
              </v-btn> -->
            </v-card-title>
            <v-card-text class="mb-10">
              <h3
                class="mt-4 text-center grey-darken-1--text"
              >
                {{ userData.roleData.roleNameForDisplay }}
              </h3>
              <h3 class="mt-4 text-center grey--text">
                {{ userData.username }}
              </h3>
              <h3 class="mt-4 text-center grey--text">
                {{ userData.email }}
              </h3>
            </v-card-text>
          </div>
          <v-divider class="mx-10" />
        </v-card>
      </v-col>
      <v-dialog
        v-model="pswdialog"
        max-width="450px"
      >
        <v-card>
          <v-card-title class="pa-3 color: primary">
            <h5 class="white--text">
              {{ $t('changepassword') }}
            </h5>
          </v-card-title>
          <div class="mx-2 my-4">
            <v-text-field
              v-model="currentpassword"
              :label="$t('currentpassword')"
              outlined
              :rules="currentPassRules"
              type="password"
              dense
            />
            <v-text-field
              v-model="newpassword"
              :label="$t('newpassword')"
              outlined
              :rules="newPasswordRules"
              type="password"
              dense
            />
            <v-text-field
              v-model="confirmpassword"
              :label="$t('confirmpassword')"
              outlined
              :rules="confirmNewPasswordRules"
              type="password"
              dense
            />
          </div>
          <div>
            <v-alert
              v-if="flag"
              dense
              outlined
              type="error"
            >
              {{ $t('passwordalerttext') }}
            </v-alert>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              class="secondary"
              width="110px"
              @click="close"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
              text
              class="primary"
              width="110px"
              :disabled="checkPasswords"
              @click="changePassword"
            >
              {{ $t('save') }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="uploadDialog"
        width="400"
        dense
      >
        <v-card>
          <v-card-title
            class="pa-3 color: primary"
            dense
          >
            <h5 class="white--text">
              {{ $t('uploadimage') }}
            </h5>
            <v-spacer />

            <v-icon
              color="white"
              aria-label="Close"
              @click="uploadDialog = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-file-input
              show-size
              counter
              multiple
              label="File input"
              @change="image"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              class="primary"
              width="110px"
              @click="upload"
            >
              {{ $t('upload') }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import countries from '../../../lib/Countries.js'
  import { LMap, LTileLayer, LControlScale, LControlLayers, LMarker, LControl } from 'vue2-leaflet'
  import 'leaflet/dist/leaflet.css'
  export default {
    components: {
      LMap,
      LTileLayer,
      LControlScale,
      LControlLayers,
      LMarker,
      LControl,
    },
    computed: {
      ...mapState({
        userData: (state) => state.authentication.userData,
      }),
      countriesList: {
        get () {
          return countries.map(c => c.name)
        },
      },
      date: {
        get () {
          return this.userData.birthdate !== null ? this.userData.birthdate.split('T')[0] : '2021-05-05'
        },
        set (newValue) {
          this.userData.birthdate = newValue.split('T')[0]
        },
      },
      currentPassRules () {
        return [value => !!value || 'This field must be filled']
      },
      newPasswordRules () {
        return [value => !!value || 'This field must be filled']
      },
      confirmNewPasswordRules () {
        return [value => value !== this.newpassword ? 'Passwords are not matching' : null]
      },
      checkPasswords () {
        if (this.newpassword !== '') {
          return this.newpassword !== this.confirmpassword
        }
        return true
      },
    },
    mounted () {
      this.addressData.markers.lat = this.userData.addressData.latitude
      this.addressData.markers.long = this.userData.addressData.longitude
      this.getMyProfileImage()
    },
    methods: {
      async changePassword () {
        const body = {
          CurrentPassword: this.currentpassword,
          NewPassword: this.confirmpassword,
        }
        await this.$store.dispatch('user/changepassword', body).then((response) => {
          if (response.resFlag) {
            this.close()
          }
        }).catch(() => {
          this.flag = true
        })
      },
      image (file) {
        if (file.length === 0) return
        var formData = new FormData()
        formData.append('FormFile', file[0])
        formData.append('FileName', file[0].name)
        this.fileData = formData
        this.imgToBase64(file[0])
      },
      async upload () {
        await this.$store.dispatch('user/uploadmyuserprofilephoto', this.fileData).then((response) => {
          if (response.resFlag) {
            this.uploadDialog = false
            this.getMyProfileImage()
          } else {
          }
        })
      },
      async getMyProfileImage () {
        await this.$store.dispatch('user/getmyuserprofilephoto').then((response) => {
          if (response.resFlag) {
            this.returnimage = 'data:image/jpeg;base64,' + response.data
          }
        })
      },
      close () {
        this.pswdialog = false
        this.currentpassword = ''
        this.newpassword = ''
        this.confirmpassword = ''
      },
      editpassword () {
        this.flag = false
        this.overlay = true
        this.pswdialog = true
      },
      onReady () {
        this.$refs.map.mapObject.invalidateSize()
      },
      onDragEnd (event) {
        const latLng = {
          latitude: this.$refs.marker.mapObject.getLatLng().lat,
          longitude: this.$refs.marker.mapObject.getLatLng().lng,
        }
        this.addressData.markers.lat = latLng.latitude.toFixed(6)
        this.addressData.markers.long = latLng.longitude.toFixed(6)
      },
      imgToBase64 (file) {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          this.returnimage = reader.result
        }
        reader.onerror = function (error) {
          error = null
        }
      },
      async save () {
        const addressbody = {
          Id: this.userData.addressData.id,
          addressType: this.userData.addressData.addressType,
          addressLine: this.userData.addressData.addressLine,
          zipCode: this.userData.addressData.zipCode,
          city: this.userData.addressData.city,
          state: this.userData.addressData.state,
          country: this.userData.addressData.country,
          longitude: this.addressData.markers.long,
          latitude: this.addressData.markers.lat,
          customerId: this.userData.addressData.customerId,
        }
        const body = {
          id: this.userData.id,
          addressId: this.userData.addressId,
          firstName: this.userData.firstName,
          lastName: this.userData.lastName,
          email: this.userData.email,
          phone: this.userData.phone,
          username: this.userData.username,
          birthdate: this.userData.birthdate,
          password: '123456789',
          gender: 'M',
          roleId: this.userData.roleId,
          customerId: this.userData.customerId,
        }
        await this.$store.dispatch('address/updateAddress', addressbody)
        await this.$store.dispatch('user/updateUser', body)
          .then(res => {
            if (res.resFlag) {
              this.positivealert = true
            } else {
              this.negativealert = true
            }
          })
      },
    },
    data () {
      return {
        flag: false,
        currentpassword: '',
        newpassword: '',
        uploadDialog: false,
        confirmpassword: '',
        overlay: false,
        pswdialog: false,
        negativealert: false,
        positivealert: false,
        email: '',
        fileData: [],
        returnimage: '',
        addressData: {
          state: '',
          city: '',
          addressLine: '',
          zipCode: '',
          country: '',
          addressType: 2,
          zoom: 15,
          markers: {
            lat: 42,
            long: 21,
          },
        },
        tileProviders: [
          {
            name: 'Google Map',
            visible: false,
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
            url: 'https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
          },
          {
            name: 'Openstreet Map',
            visible: true,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
          },
        ],
      }
    },
  }
</script>
<style scoped>
 .v-text-field {
     margin: 0 20px;
 }
 .positionBadge {
     margin: 0 auto;
     border-radius: 10px;
 }
 .borderRadius {
   border: 1px solid lightgrey;
   border-radius: 5px;
 }
 .imageclass{
   border-radius: 50%;
 }
</style>
